import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import "./IconWithTooltip.css";

export const errorMessage = {
  measurement_error: "Measurement Error",
  offset_applied: "Offset Applied",
  standard_check_error: "Standard Check Error",
  calibration_error: "Calibration Error",
};

class IconWithTooltip extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { errors, iconStyle } = this.props;

    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="tooltip">
            {errors.map(function (error, index) {
              if (errorMessage[error]) {
                return <div key={index}>{errorMessage[error]}</div>;
              }
              return null;
            })}
          </Tooltip>
        }
      >
        <FontAwesome
          className="tooltip-icon"
          name="exclamation-triangle"
          style={iconStyle}
        />
      </OverlayTrigger>
    );
  }
}

IconWithTooltip.propTypes = {
  errors: PropTypes.array,
  iconStyle: PropTypes.object,
};

export default IconWithTooltip;
