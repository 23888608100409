import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import DayPicker from "react-day-picker/DayPicker";
import "react-day-picker/lib/style.css";
import "./DateSelector.css";
import moment from "moment";
import { getHoverRange, getDateObject } from "./DateRangeSelectorHelper";
import CalendarTimePicker from "./CalendarTimePicker";
import "./CalendarOverlay.scss";
import {
  RELATIVE_TIME_RANGE_OPTIONS,
  RELATIVE_DATE_RANGE_OPTIONS,
} from "./RelativeRangeOptions";

const CALENDAR_VIEW = {
  relative_range_option: RELATIVE_DATE_RANGE_OPTIONS,
  width: "400px",
};

const CALENDAR_WITH_TIME_PICKER_VIEW = {
  relative_range_option: {
    ...RELATIVE_TIME_RANGE_OPTIONS,
    ...RELATIVE_DATE_RANGE_OPTIONS,
  },
  width: "585px",
};

const CalendarOverlay = (props) => {
  const dateOverlayRef = React.createRef();
  const timePickerRef = React.createRef();
  const [isTimeSelectorOpen, setIsTimeSelectorOpen] = useState(false);

  const from = getDateObject(props.from, props.utc);
  const to = getDateObject(props.to, props.utc);
  const [hoverRange, setHoverRange] = useState(
    getHoverRange(props.hoverRange, props.from)
  );

  const modifiers = {
    start: from,
    end: to,
    selectedRange: { from: from, to: to },
    ...hoverRange,
  };

  const [initialMonth, setInitialMonth] = useState(() => {
    if (props.initialMonth) {
      return props.initialMonth === "to" ? to : from;
    }

    return props.activeKey === "to" ? to : from;
  });

  const clickEvent = (event) => {
    // close popup if user click outside of the popup
    if (
      !isTimeSelectorOpen &&
      !dateOverlayRef.current?.contains(event.target) &&
      !props.dateRangeSelectorContainerRef.current?.contains(event.target)
    ) {
      props.closeCalendar();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickEvent);

    return function cleanup() {
      document.removeEventListener("mousedown", clickEvent);
    };
  });

  useEffect(() => {
    if (props.activeKey === "relativeDayRange") {
      setInitialMonth(props.from);
    }
  }, [props.activeKey, props.from]);

  const onDayMouseEnter = (date) => {
    setHoverRange(getHoverRange(props.hoverRange, date));
  };

  const onDayMouseLeave = () => {
    setHoverRange(null);
  };

  const relativeRangeOptions = props.allowTimeSelection
    ? CALENDAR_WITH_TIME_PICKER_VIEW.relative_range_option
    : CALENDAR_VIEW.relative_range_option;

  return (
    <div
      className="calendarOverlayContainer"
      style={{
        left: `${props.calendarPosition}px`,
        width: props.allowTimeSelection
          ? CALENDAR_WITH_TIME_PICKER_VIEW.width
          : CALENDAR_VIEW.width,
      }}
      ref={dateOverlayRef}
    >
      <div className="dateSelector">
        <DayPicker
          modifiers={modifiers}
          month={moment(initialMonth).toDate()}
          numberOfMonths={2}
          onDayClick={(date, modifiers) =>
            props.onDayChange(props.activeKey, date, modifiers)
          }
          onDayMouseEnter={props.hoverRange ? onDayMouseEnter : null}
          onDayMouseLeave={props.hoverRange ? onDayMouseLeave : null}
          disabledDays={props.disabledDays}
        />

        {props.allowTimeSelection && (
          <>
            <div className="divider" />

            <CalendarTimePicker
              from={from}
              to={to}
              timePickerRef={timePickerRef}
              setIsTimeSelectorOpen={setIsTimeSelectorOpen}
              onTimeChange={props.onTimeChange}
            />
          </>
        )}
      </div>

      <div className="relativeRangeOptionsContainer">
        {!props.disableRelativeDays &&
          Object.keys(relativeRangeOptions).map((key) => {
            const option = relativeRangeOptions[key];
            return (
              <Button
                key={key}
                onClick={() => props.onRelativeDayRangeSelect(option)}
              >
                {option.label}
              </Button>
            );
          })}
      </div>
    </div>
  );
};

CalendarOverlay.propTypes = {
  dateRangeSelectorContainerRef: PropTypes.object,
  from: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  to: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  calendarPosition: PropTypes.number,
  activeKey: PropTypes.string,
  closeCalendar: PropTypes.func,
  onDayChange: PropTypes.func,
  pullRight: PropTypes.bool,
  disableRelativeDays: PropTypes.bool,
  disabledDays: PropTypes.array,
  initialMonth: PropTypes.oneOf(["from", "to"]),
  onRelativeDayRangeSelect: PropTypes.func,
  hoverRange: PropTypes.oneOf(["monthly", "weekly"]),
  allowTimeSelection: PropTypes.bool,
  onTimeChange: PropTypes.func.isRequired,
  utc: PropTypes.bool,
};

export default CalendarOverlay;
