import React from "react";
import * as PropTypes from "prop-types";
import { Col, Row, ControlLabel } from "react-bootstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate } from "react-day-picker/moment";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import "./DateInput.css";
import config from "../../../config";
import FormInputTitle from "./FormInputTitle";

const DateInput = (props) => {
  const onDayChange = (value) => {
    const time = getTimeStringFromDate(value, props.format);
    if (time !== "-") {
      props.onDayChange(value);
    }
  };

  return (
    <Row className="formRows">
      <Col
        componentClass={ControlLabel}
        sm={props.titleCol}
      >
        <FormInputTitle
          title={props.title}
          message={props.message}
          pullLeft={props.pullLeft}
        />
      </Col>
      <Col
        xs={12}
        sm={12 - props.titleCol}
      >
        <DayPickerInput
          className="dateInput"
          placeholder={getTimeStringFromDate(props.value, props.format)}
          value={getTimeStringFromDate(props.value, props.format)}
          onDayChange={onDayChange}
          formatDate={formatDate}
          format={props.format}
          dayPickerProps={props.dayPickerProps}
          inputProps={{ disabled: props.disabled }}
        />
        {props.onChangeWarningComponent}
      </Col>
    </Row>
  );
};

DateInput.defaultProps = {
  format: config.DATE_FORMAT,
};

DateInput.propTypes = {
  title: PropTypes.string,
  titleCol: PropTypes.number,
  message: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  format: PropTypes.string,
  dayPickerProps: PropTypes.object,
  onDayChange: PropTypes.func.isRequired,
  onChangeWarningComponent: PropTypes.node,
  pullLeft: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DateInput;
