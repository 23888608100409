import React from "react";
import TimePicker from "rc-time-picker";
import * as PropTypes from "prop-types";
import config from "#config";
import moment from "moment";
import { getTimeStringFromDate } from "#utils/timeFormatter";

const TimePickerContainer = (props) => {
  const {
    keyValue,
    title,
    value,
    timePickerRef,
    onTimeChange,
    setIsTimeSelectorOpen,
  } = props;

  return (
    <div className="timePickerContainer">
      <div className="timePickerContainer__title">{title}</div>
      <div className="timePickerContainer__date">
        {getTimeStringFromDate(value, config.DATE_FORMAT)}
      </div>
      <TimePicker
        ref={timePickerRef}
        value={value}
        showSecond={true}
        allowEmpty={false}
        placement="bottomRight"
        onChange={(value) => onTimeChange(keyValue, value)}
        onOpen={() => setIsTimeSelectorOpen(true)}
        onClose={() => setIsTimeSelectorOpen(false)}
      />
    </div>
  );
};

const CalendarTimePicker = (props) => {
  const { from, to, timePickerRef, setIsTimeSelectorOpen, onTimeChange } =
    props;

  return (
    <div className="calendarTimePicker">
      <TimePickerContainer
        title="From"
        keyValue="from"
        value={moment(from)}
        timePickerRef={timePickerRef}
        onTimeChange={onTimeChange}
        setIsTimeSelectorOpen={setIsTimeSelectorOpen}
      />

      <TimePickerContainer
        title="To"
        keyValue="to"
        value={moment(to)}
        timePickerRef={timePickerRef}
        onTimeChange={onTimeChange}
        setIsTimeSelectorOpen={setIsTimeSelectorOpen}
      />
    </div>
  );
};

TimePickerContainer.propTypes = {
  keyValue: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  timePickerRef: PropTypes.object.isRequired,
  setIsTimeSelectorOpen: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired,
};

CalendarTimePicker.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  timePickerRef: PropTypes.object.isRequired,
  setIsTimeSelectorOpen: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired,
};

export default CalendarTimePicker;
