import React from "react";
import PropTypes from "prop-types";
import { CustomList } from "../List/CustomList";
import Note from "./Note";
import "./NotesList.scss";
import sortBy from "lodash/sortBy";

const NotesList = (props) => {
  // measure is a function to run after delayed height changes
  // (e.g. image loading) so that the row can be resized
  const rowRenderer = (data, measure) => (
    <Note
      testNote={data}
      measure={measure}
    />
  );

  const sortedList = sortBy(props.list, "date", "asc");
  const rowCount = sortedList?.length ?? 0;

  return (
    <div>
      <CustomList
        title="notes"
        height={props.height}
        rowHeight={60}
        list={sortedList}
        listItemRenderer={rowRenderer}
        className={"notesList"}
        rowClassName={"notesList__row"}
        flexibleRowHeight
        scrollToIndex={rowCount}
      />
    </div>
  );
};

NotesList.propTypes = {
  height: PropTypes.number,
  list: PropTypes.array,
};

export default NotesList;
