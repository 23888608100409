import { FormInputWrapper, styles } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import cssStyles from "./CreatableSelectInput.module.scss";

const cx = classNames.bind(cssStyles);

const DropdownIndicator = (props) => {
  const { isValidated, isDisabled, isError } = props.selectProps;

  return (
    <components.DropdownIndicator {...props}>
      {isValidated && !isDisabled && isError ? (
        <div className={cx("icon", "icon--error")}>
          <FontAwesome name="exclamation-circle" />
        </div>
      ) : (
        <div className={cx("icon", { "icon--disabled": isDisabled })}>
          <FontAwesome name="check" />
        </div>
      )}
    </components.DropdownIndicator>
  );
};

const customStyles = {
  control: (provided, state) => {
    const hasError = state?.selectProps?.isError;

    let borderStyles = { border: `1px solid ${styles.surface["100"]}` };

    if (state.isDisabled) {
      borderStyles = { border: `1px solid ${styles.surface["200"]}` };
    } else if (hasError) {
      borderStyles = { border: `1px solid ${styles.status.error}` };
    } else if (state.isFocused) {
      borderStyles = { border: `1px solid ${styles.primary["500"]}` };
    }

    return {
      ...provided,
      minWidth: "120px",
      cursor: "pointer",
      borderRadius: "6px",
      padding: "12px 16px",
      color: state.isDisabled ? styles.text["400"] : `${styles.text["800"]}`,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.01em",
      caretColor: hasError ? styles.status.error : `${styles.primary["500"]}`,
      boxShadow: "none",
      "&:hover": {
        border: hasError
          ? `1px solid ${styles.status.error}`
          : `1px solid ${styles.primary["500"]}`,
      },
      ...borderStyles,
    };
  },
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  placeholder: (provided) => ({ ...provided, margin: 0 }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    paddingLeft: "8px",
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    backgroundColor: state.isSelected
      ? styles.primary["100"]
      : state.isFocused
        ? styles.primary["50"]
        : "#fff",
    ":active": {
      backgroundColor: styles.primary["100"],
    },
    padding: "8px 12px",
  }),
};

const CreatableSelectInput = (props) => {
  return (
    <div className={`${styles.wrapper}`}>
      <div className={styles.inputContainer}>
        <CreatableSelect
          {...props}
          components={
            props.showIcon && props.isValidated
              ? { DropdownIndicator }
              : undefined
          }
          styles={customStyles}
        />
      </div>
    </div>
  );
};

CreatableSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.object,
  defaultInputValue: PropTypes.string,
  options: PropTypes.array,
  onCreateOption: PropTypes.func,
  isDisabled: PropTypes.bool,
  showIcon: PropTypes.bool,
  isRequired: PropTypes.bool,
  isValidated: PropTypes.bool,
};

DropdownIndicator.propTypes = {
  isSelected: PropTypes.bool,
  selectProps: PropTypes.shape({
    isValidated: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isError: PropTypes.bool,
  }),
};

const ControlledCreatableSelectInput = (props) => {
  return (
    <FormInputWrapper
      {...props}
      as={CreatableSelectInput}
    />
  );
};

export default ControlledCreatableSelectInput;
