import React from "react";
import * as PropTypes from "prop-types";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { arrayMove } from "#utils/arrayFormatter";
import FontAwesome from "react-fontawesome";
import { getListContainerHeight } from "./ListHelper";
import "./OrderedList.scss";

const SortableItem = sortableElement(({ value, sortIndex, style }) => {
  return (
    <li
      className="orderedList__item"
      style={style}
    >
      <FontAwesome name="bars" />

      <span>{sortIndex + 1}</span>

      <span>{value}</span>
    </li>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className="orderedList__container">{children}</ul>;
});

const OrderedList = (props) => {
  const {
    className,
    list,
    rowHeight,
    maxVisibleRows,
    labelKey,
    style,
    rowStyle,
    disabled,
  } = props;

  const containerHeight = getListContainerHeight(
    maxVisibleRows,
    rowHeight,
    list
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    props.onChange(arrayMove(props.list, oldIndex, newIndex));
  };

  const containerStyle = {
    ...style,
    height: containerHeight || null,
  };

  const itemStyle = {
    ...rowStyle,
    height: rowHeight || null,
    cursor: disabled ? "auto" : null,
  };

  return (
    <div
      className={`${className} orderedList ${disabled ? "disabled" : null}`}
      style={containerStyle}
    >
      <SortableContainer
        onSortEnd={onSortEnd}
        helperClass="orderedList__item--focus"
      >
        {list.map((value, index) => {
          const label = labelKey ? value[labelKey] : value;

          return (
            <SortableItem
              key={label}
              index={index}
              value={label}
              sortIndex={index}
              style={itemStyle}
              disabled={disabled}
            />
          );
        })}
      </SortableContainer>
    </div>
  );
};

OrderedList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  labelKey: PropTypes.string,
  rowHeight: PropTypes.number,
  maxVisibleRows: PropTypes.number,
  style: PropTypes.object,
  rowStyle: PropTypes.object,
  disabled: PropTypes.bool,
};

export default OrderedList;
