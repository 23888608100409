import React, { useState, useRef, useEffect } from "react";
import * as PropTypes from "prop-types";
import "react-day-picker/lib/style.css";
import "./DateRangeSelector.scss";
import "./DateSelector.css";
import moment from "moment";
import { AssertIsAfterOrEqualDate } from "../../../utils/assert";
import config from "../../../config";
import CalendarOverlay from "./CalendarOverlay";
import FontAwesome from "react-fontawesome";
import {
  isDisabledDay,
  isValidDaySelection,
  getHoverRange,
  getRelativeOptions,
  getMoment,
  getFormatDate,
  getDateObject,
  isValidDateInput,
} from "./DateRangeSelectorHelper";

const RELATIVE_DATE_OFFSET = 300;

const DATETIME_RELATIVE_DATE_OFFSET = 490;

const DATE_SELECTOR_VIEW = {
  input_width: "80px",
  date_format: config.DATE_FORMAT,
  overlay_offset: 225,
};

const DATETIME_SELECTOR_VIEW = {
  input_width: "150px",
  date_format: config.DATETIME_NO_TIMEZONE_FORMAT,
  overlay_offset: 310,
};

const DateInput = (props) => (
  <input
    type="text"
    name="date"
    style={{
      width: props.allowTimeSelection
        ? DATETIME_SELECTOR_VIEW.input_width
        : DATE_SELECTOR_VIEW.input_width,
    }}
    value={props.value}
    onClick={() => props.openCalendar(props.activeKey)}
    onChange={(input) =>
      props.onInputChange(props.activeKey, input, props.hoverRange)
    }
    onBlur={(input) => props.onInputBlur(props.activeKey, input)}
    disabled={props.disabled}
  />
);

const DateRangeSelector = (props) => {
  const {
    className,
    dateRange,
    icon,
    disabled,
    relativeToDate,
    disableRelativeDays,
    disabledDays,
    disableFrom,
    allowOneDayRange,
    initialMonth,
    hoverRange,
    allowTimeSelection,
    utc,
  } = props;

  const dateRangeSelectorContainerRef = useRef();
  const dateFormat = allowTimeSelection
    ? DATETIME_SELECTOR_VIEW.date_format
    : DATE_SELECTOR_VIEW.date_format;

  const [from, setFrom] = useState(
    getMoment(dateRange.from, utc).startOf("day").toDate()
  );
  const [to, setTo] = useState(
    getMoment(dateRange.to, utc).endOf("day").toDate()
  );

  const [calendarToggle, setCalendarToggle] = useState(false);
  const [calendarPosition, setCalendarPosition] = useState(0);
  const [activeKey, setActiveKey] = useState("from");

  const [fromInputbox, setFromInputbox] = useState(
    getFormatDate(dateRange.from ?? from, dateFormat, utc)
  );
  const [toInputbox, setToInputbox] = useState(
    getFormatDate(dateRange.to ?? to, dateFormat, utc)
  );
  const [relativeDayRange, setRelativeDayRange] = useState(
    getRelativeOptions(props.relativeDayRange)
  );

  const onInputChange = (activeKey, event) => {
    const value = event.target.value;
    let otherDate = null;

    if (activeKey === "from") {
      setFromInputbox(value);
      otherDate = toInputbox;
    } else {
      setToInputbox(value);
      otherDate = fromInputbox;
    }

    // sync the changes to the calendar overlay state if the input is valid
    if (
      moment(moment(value), dateFormat, true).isValid() &&
      !isDisabledDay(value, disabledDays) &&
      isValidDateInput(moment(value), activeKey, otherDate)
    ) {
      if (activeKey === "from") {
        setFrom(moment(value).toDate());
      }
      if (activeKey === "to") {
        setTo(moment(value).toDate());
      }
    }
  };

  // If the input is invalid and is blurred, return to latest valid state
  // as stored by the parent component
  const onInputBlur = (activeKey, event) => {
    const value = event.target.value;
    const otherDate = activeKey === "from" ? to : from;

    if (!isValidDateInput(moment(value), activeKey, otherDate)) {
      if (activeKey === "from") {
        setFrom(dateRange.from);
        setFromInputbox(getFormatDate(dateRange.from, dateFormat, utc));
      }
      if (activeKey === "to") {
        setTo(dateRange.to);
        setToInputbox(getFormatDate(dateRange.to, dateFormat, utc));
      }
    }
  };

  const onDayChange = (activeKey, selectedDate, modifiers = {}) => {
    if (!selectedDate || modifiers?.disabled) {
      return resetInput();
    }

    if (modifiers?.hoverRange) {
      const { hoverRangeStart, hoverRangeEnd } = getHoverRange(
        props.hoverRange,
        selectedDate
      );

      updateFromDate(hoverRangeStart);
      updateToDate(hoverRangeEnd);
      return;
    }

    if (activeKey === "from" && disableFrom) {
      setActiveKey("to");
      activeKey = "to";
    }

    const isValidSelection = isValidDaySelection(
      activeKey,
      selectedDate,
      from,
      to,
      allowOneDayRange
    );

    if (isValidSelection) {
      if (activeKey === "from") {
        const date = utc ? moment.utc(from) : moment(from);
        const newDate = date
          .set({
            year: moment(selectedDate).year(),
            month: moment(selectedDate).month(),
            date: moment(selectedDate).date(),
          })
          .toDate();

        updateFromDate(newDate);
      }

      if (activeKey === "to") {
        const date = utc ? moment.utc(to) : moment(to);

        const newDate = date
          .set({
            year: moment(selectedDate).year(),
            month: moment(selectedDate).month(),
            date: moment(selectedDate).date(),
          })
          .toDate();

        updateToDate(newDate);
      }

      setRelativeDayRange(null);
    } else if (activeKey === "relativeDayRange") {
      if (AssertIsAfterOrEqualDate(selectedDate, to, "day")) {
        const newDate = moment(selectedDate).endOf("day").toDate();
        updateToDate(newDate);

        setActiveKey("to");
        setRelativeDayRange(null);
      } else {
        const newDate = moment(selectedDate).startOf("day").toDate();
        updateFromDate(newDate);

        setActiveKey("from");
        setRelativeDayRange(null);
      }
    } else {
      resetInput();
    }
  };

  const onTimeChange = (key, time) => {
    if (key === "from") {
      updateFromDate(time.toDate());
      setActiveKey("from");
    }

    if (key === "to") {
      updateToDate(time.toDate());
      setActiveKey("to");
    }

    setRelativeDayRange(null);
  };

  const onRelativeDayRangeSelect = (
    selectedRange,
    relativeToDate = () => moment()
  ) => {
    const today = moment(relativeToDate());
    const to = today.toDate(); //today
    const from = today
      .subtract(selectedRange.value, selectedRange.unit)
      .toDate();

    setRelativeDayRange(selectedRange);
    setActiveKey("relativeDayRange");
    updateFromDate(from);
    updateToDate(to);
  };

  const getCalendarPosition = () => {
    const inputPosition = dateRangeSelectorContainerRef.current.offsetLeft;
    let overlayPosition = 0;
    let overlayOffset = 0;

    if (relativeDayRange) {
      overlayOffset = props.allowTimeSelection
        ? DATETIME_RELATIVE_DATE_OFFSET
        : RELATIVE_DATE_OFFSET;
    } else {
      overlayOffset = props.allowTimeSelection
        ? DATETIME_SELECTOR_VIEW.overlay_offset
        : DATE_SELECTOR_VIEW.overlay_offset;
    }

    overlayPosition = Math.max(inputPosition - overlayOffset, 0);

    return overlayPosition;
  };

  const openCalendar = (key) => {
    const calendarPosition = getCalendarPosition();

    setActiveKey(key);
    setCalendarPosition(calendarPosition);
    setCalendarToggle(true);
  };

  const closeCalendar = () => {
    setCalendarToggle(false);
    resetInput();

    if (allowTimeSelection) {
      props.onDateRangeChange(
        moment(from).toDate(),
        moment(to).toDate(),
        relativeDayRange
      );
    } else {
      if (utc) {
        props.onDateRangeChange(
          getDateObject(from, utc),
          getDateObject(to, utc),
          relativeDayRange
        );
      } else {
        props.onDateRangeChange(
          moment(from).startOf("day").toDate(),
          moment(to).endOf("day").toDate(),
          relativeDayRange
        );
      }
    }
  };

  // If we update the selected date then user's input value should be updated also.
  const updateFromDate = (date) => {
    setFrom(date);
    setFromInputbox(getFormatDate(date, dateFormat, utc));
  };

  const updateToDate = (date) => {
    setTo(date);
    setToInputbox(getFormatDate(date, dateFormat, utc));
  };

  const resetInput = () => {
    setFromInputbox(getFormatDate(from, dateFormat, utc));
    setToInputbox(getFormatDate(to, dateFormat, utc));
  };

  // If calendar is closed and dateRange is changed (e.g. clearing from/to
  // query params), sync the date to the changed values
  useEffect(() => {
    if (!calendarToggle && (from !== dateRange.from || to !== dateRange.to)) {
      setFrom(dateRange.from);
      setTo(dateRange.to);
      setFromInputbox(getFormatDate(dateRange.from, dateFormat, utc));
      setToInputbox(getFormatDate(dateRange.to, dateFormat, utc));
    }
  }, [dateRange.from, dateRange.to]);

  return (
    <div
      className={`dateRangeSelectorContainer ${className}`}
      ref={dateRangeSelectorContainerRef}
    >
      {icon ? (
        <FontAwesome
          name="calendar"
          className="icon"
        />
      ) : null}

      {relativeDayRange ? (
        <div
          className="dateRangeSelector__relativeDayRange"
          disabled={disabled}
          onClick={() => openCalendar("relativeDayRange")}
        >
          {`Last ${relativeDayRange.value} ${relativeDayRange.unit}`}
        </div>
      ) : (
        <div className="fromToInputBoxes">
          <DateInput
            value={fromInputbox}
            activeKey="from"
            disabled={disabled || disableFrom}
            openCalendar={openCalendar}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            hoverRange={hoverRange}
            allowTimeSelection={allowTimeSelection}
          />
          —
          <DateInput
            value={toInputbox}
            activeKey="to"
            disabled={disabled}
            openCalendar={openCalendar}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
            hoverRange={hoverRange}
            allowTimeSelection={allowTimeSelection}
          />
        </div>
      )}

      {calendarToggle ? (
        <CalendarOverlay
          dateRangeSelectorContainerRef={dateRangeSelectorContainerRef}
          calendarPosition={calendarPosition}
          from={from}
          to={to}
          activeKey={activeKey}
          onDayChange={onDayChange}
          onTimeChange={onTimeChange}
          onRelativeDayRangeSelect={(numDays) =>
            onRelativeDayRangeSelect(numDays, relativeToDate)
          }
          closeCalendar={closeCalendar}
          disableRelativeDays={disableRelativeDays}
          disabledDays={disabledDays}
          initialMonth={initialMonth}
          hoverRange={hoverRange}
          allowTimeSelection={allowTimeSelection}
          utc={utc}
        />
      ) : null}
    </div>
  );
};

DateInput.propTypes = {
  value: PropTypes.string,
  activeKey: PropTypes.string,
  disabled: PropTypes.bool,
  openCalendar: PropTypes.func,
  onInputChange: PropTypes.func,
  onInputBlur: PropTypes.func,
  hoverRange: PropTypes.oneOf(["weekly", "monthly"]),
  allowTimeSelection: PropTypes.bool,
};

DateRangeSelector.propTypes = {
  className: PropTypes.string,
  dateRange: PropTypes.object.isRequired,
  relativeDayRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDateRangeChange: PropTypes.func,
  icon: PropTypes.bool,
  pullRight: PropTypes.bool,
  disabled: PropTypes.bool,
  relativeToDate: PropTypes.func,
  disableRelativeDays: PropTypes.bool,
  disabledDays: PropTypes.array,
  disableFrom: PropTypes.bool,
  allowOneDayRange: PropTypes.bool,
  initialMonth: PropTypes.oneOf(["from", "to"]),
  hoverRange: PropTypes.oneOf(["weekly", "monthly"]),
  allowTimeSelection: PropTypes.bool,
  utc: PropTypes.bool,
};

export default DateRangeSelector;
