import React from "react";

import * as PropTypes from "prop-types";

import { MultiDropdownInputWithSearch } from "@validereinc/common-components";

/**
 * @deprecated use <DataTablePanel /> with baked in table config instead
 */
const ColumnSelect = ({ value, options, onChange, localStorageKey }) => {
  const onChangeValue = (newValue) => {
    const formatted = newValue?.length === 0 ? options : newValue;

    onChange(formatted);

    if (localStorageKey) {
      const headerKeys = formatted.map(({ key }) => ({ key }));

      localStorage.setItem(localStorageKey, JSON.stringify(headerKeys));
    }
  };

  return (
    <MultiDropdownInputWithSearch
      label="Edit Columns"
      value={value.length === options.length ? [] : value}
      options={options}
      labelKey="label"
      onChange={onChangeValue}
      isClearable
      align="right"
    />
  );
};

ColumnSelect.propTypes = {
  value: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  localStorageKey: PropTypes.string,
};

export default ColumnSelect;
