import React from "react";
import PropTypes from "prop-types";
import { Col, Row, FormControl, ControlLabel } from "react-bootstrap";
import "./TextBoxInput.css";
import FormInputTitle from "./FormInputTitle";

const TextBoxInput = (props) => {
  return (
    <Row className="formRows">
      <Col
        componentClass={ControlLabel}
        sm={props.titleCol}
      >
        <FormInputTitle
          title={props.title}
          message={props.message}
          pullLeft={props.pullLeft}
        />
      </Col>
      <Col sm={props.inputCol ?? 12 - props.titleCol}>
        <div className="form__input">
          <FormControl
            className={`TextBoxInput__formControl ${
              props.className ? props.className : ""
            }`}
            style={props.style}
            type={props.type}
            onChange={props.onChange}
            value={props.value ? props.value : ""}
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
          {props.unit && <span className="form__unit">{props.unit}</span>}
        </div>
      </Col>
    </Row>
  );
};

TextBoxInput.propTypes = {
  titleCol: PropTypes.number,
  inputCol: PropTypes.number,
  title: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  message: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  pullLeft: PropTypes.bool,
  unit: PropTypes.string,
};

export default TextBoxInput;
