import { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import useQueryParams from "#src/Routers/useQueryParams";
import isEmpty from "lodash/isEmpty";
import { usePrevious } from "@validereinc/common-components";

export const DEFAULT_PAGINATION_VALUES = {
  page: 1,
  rowPerPage: 25,
  sort: { started_at: "desc" },
};

const PAGINATION_QUERY_PARAMS = {
  page: null,
  num_rows: null,
};

function parseToInt(string) {
  try {
    const number = parseInt(string);

    return !isNaN(number) ? number : null;
  } catch {
    return null;
  }
}

// A hook for interfacing with the PaginationController component.
const usePagination = (initialPaginationValue, setLastFetchDate) => {
  const [paginationDetail, setPaginationDetail] = useState({
    ...DEFAULT_PAGINATION_VALUES,
    ...initialPaginationValue,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);

  const onPaginationChange = (detail) => {
    const { rowPerPage, sort } = detail;
    let { page } = detail;

    setPaginationDetail((previousDetail) => {
      if (previousDetail.rowPerPage !== rowPerPage) {
        page = 1;
      }

      if (!isEqual(previousDetail.sort, sort)) {
        page = 1;
      }

      return {
        ...previousDetail,
        page,
        rowPerPage,
        sort,
      };
    });

    setLastFetchDate(new Date());
  };

  return [
    paginationDetail,
    onPaginationChange,
    totalRowCount,
    setTotalRowCount,
  ];
};

// A hook for interfacing with the PaginationController component with query params
const usePaginationWithQueryParam = (
  initialPaginationValue,
  setLastFetchDate
) => {
  const initialPaginationValueWithDefaults = {
    ...DEFAULT_PAGINATION_VALUES,
    ...initialPaginationValue,
  };

  const [queryParams, setQueryParams] = useQueryParams(PAGINATION_QUERY_PARAMS);

  const [sortBy, setSortBy] = useState(initialPaginationValueWithDefaults.sort);
  const [totalRowCount, setTotalRowCount] = useState(0);

  const onPaginationChange = (detail) => {
    const { rowPerPage, sort } = detail;
    let { page } = detail;

    // if num_rows exist and is different from the new paginationDetail rowPerPage,
    // it is a rowPerPage change and should be reverted back to page 1
    if (
      queryParams.num_rows &&
      parseToInt(queryParams.num_rows) !== rowPerPage
    ) {
      page = 1;
    }

    // a sortBy change should also revert it back to first page
    if (!isEqual(sort, sortBy)) {
      page = 1;
    }

    setQueryParams({ page, num_rows: rowPerPage });
    setSortBy(sort);

    setLastFetchDate(new Date());
  };

  const paginationDetail = {
    page:
      parseToInt(queryParams.page) ?? initialPaginationValueWithDefaults.page,
    rowPerPage:
      parseToInt(queryParams.num_rows) ??
      initialPaginationValueWithDefaults.rowPerPage,
    sort: sortBy,
  };

  return [
    paginationDetail,
    onPaginationChange,
    totalRowCount,
    setTotalRowCount,
  ];
};

/**
 * @param {*} requestService the fetch request
 * @param {*} usePaginationHook The hook managing the pagination
 * @param {*} filterInputs The object representing all applicable filters for the request
 * @param {*} initialPaginationValue initial values for the pagination hook
 * @param {*} isFilterInputsLoading The boolean indicating if filterInputs is still loading,
 *   useful to not start extra fetches/reset pagination page
 * @returns An array containing the data fetched, A boolean whether or not a request
 * is still ongoing, the paginationDetail and onPaginationChange needed to update the
 * PaginationController
 */
const usePaginationHookWithRequestService = (
  usePaginationHook,
  requestService,
  filterInputs,
  initialPaginationValue,
  isFilterInputsLoading = false
) => {
  const initialPaginationValueWithDefaults = {
    ...DEFAULT_PAGINATION_VALUES,
    ...initialPaginationValue,
  };

  const [data, setData] = useState([]);
  const [loadingState, setLoadingState] = useState([]);
  const [lastFetchDate, setLastFetchDate] = useState(null);

  const refetchData = () => {
    setLastFetchDate(new Date());
  };

  const [
    paginationDetail,
    onPaginationChange,
    totalRowCount,
    setTotalRowCount,
  ] = usePaginationHook(initialPaginationValueWithDefaults, setLastFetchDate);

  const previousFilterInputs = usePrevious(filterInputs) ?? {};
  const filterInputsChanged =
    !isEqual(previousFilterInputs, filterInputs) &&
    !isEmpty(previousFilterInputs);

  useEffect(() => {
    if (isFilterInputsLoading) {
      return;
    }

    if (filterInputsChanged) {
      onPaginationChange({
        ...paginationDetail,
        page: 1,
      });
    } else {
      setLoadingState((loadingState) => [...loadingState, "loading"]);

      requestService(paginationDetail, filterInputs)
        .then(({ data }) => {
          setData(data.data);
          setTotalRowCount(data.total_entries);
        })
        .finally(() =>
          setLoadingState((loadingState) => loadingState.slice(0, -1))
        );
    }
  }, [filterInputsChanged, isFilterInputsLoading, lastFetchDate]);

  // Because fetches can be queued up before the first one finishes,
  // use a stack to determine if there are any ongoing fetches
  const isLoading = loadingState.length !== 0;

  return [
    data,
    isLoading,
    { ...paginationDetail, totalRowCount },
    onPaginationChange,
    refetchData,
  ];
};

export const useFetchPaginatedData = (
  requestService,
  filterInputs,
  initialPaginationValue
) => {
  return usePaginationHookWithRequestService(
    usePagination,
    requestService,
    filterInputs,
    initialPaginationValue
  );
};

export const useFetchPaginatedDataWithQueryParam = (
  requestService,
  filterInputs,
  initialPaginationValue,
  isLoading
) => {
  return usePaginationHookWithRequestService(
    usePaginationWithQueryParam,
    requestService,
    filterInputs,
    initialPaginationValue,
    isLoading
  );
};

export const useFetchPaginatedDataWithoutFilters = (requestService) => {
  return usePaginationHookWithRequestService(
    usePaginationWithQueryParam,
    requestService
  );
};
