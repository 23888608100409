import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Col, Row, ControlLabel } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import "./SelectInput.scss";
import FormInputTitle from "./FormInputTitle";

function getOptionsWithLabels(options, labelKey) {
  if (labelKey) {
    return options.map((option) => {
      option.label = option[labelKey];
      option.value = option[labelKey];
      return option;
    });
  } else {
    return options
      .filter((option) => option)
      .map((option) => ({
        label: option,
        value: option,
      }));
  }
}

const customStyle = {
  option: (provided) => ({
    ...provided,
    borderRadius: 0,
  }),
};

const SelectInput = (props) => {
  const option = useMemo(
    () => getOptionsWithLabels(props.options, props.labelKey),
    [props.options, props.labelKey]
  );

  const value = props.value
    ? getOptionsWithLabels(props.value, props.labelKey)
    : null;

  const onMultipleSelectChange = (selectedValues) => {
    if (!props.labelKey) {
      selectedValues = selectedValues?.map((value) => value.label) ?? [];
    }

    props.onChange(selectedValues ?? []);
  };

  const onSelectChange = (selectedValue) => {
    if (!props.labelKey) {
      selectedValue = selectedValue?.value;
    }

    props.onChange(selectedValue);
  };

  return (
    <Row className={`formRows selectInput ${props.className ?? ""}`}>
      <Col
        componentClass={ControlLabel}
        sm={props.titleCol}
      >
        <FormInputTitle
          title={props.title}
          message={props.message}
          messageClassName={props.messageClassName}
          pullLeft={props.pullLeft}
        />
      </Col>
      <Col sm={12 - props.titleCol}>
        <Select
          className="react-select"
          classNamePrefix="react-select"
          styles={customStyle}
          value={value}
          options={option}
          onChange={(selectedValue) =>
            props.isMulti
              ? onMultipleSelectChange(selectedValue)
              : onSelectChange(selectedValue)
          }
          isMulti={props.isMulti}
          filterOption={createFilter({
            ignoreAccents: false,
            ignoreCase: props.ignoreCase,
          })}
          isDisabled={props.isDisabled}
          isLoading={props.isLoading}
          closeMenuOnSelect={props.closeMenuOnSelect}
          placeholder={props.placeholder}
          isClearable={props.isClearable}
        />
        {props.onChangeWarningComponent}
      </Col>
    </Row>
  );
};

SelectInput.defaultProps = {
  ignoreCase: false,
};

SelectInput.propTypes = {
  titleCol: PropTypes.number,
  title: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string,
  messageClassName: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.array,
  labelKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  ignoreCase: PropTypes.bool,
  onChangeWarningComponent: PropTypes.node,
  isLoading: PropTypes.bool,
  pullLeft: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isClearable: PropTypes.bool,
};

export default SelectInput;
