import React from "react";

import styles from "./AlertMessageWithLink.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

type AlertMessageWithLinkProps = {
  mainText: string;
  linkText: string;
  onLinkClick: () => void;
  position?: "right" | "bottom";
};

export const AlertMessageWithLink = ({
  mainText,
  onLinkClick,
  linkText,
  position = "right",
}: AlertMessageWithLinkProps) => (
  <div
    className={cx("container", {
      "container-row": position === "right",
      "container-column": position === "bottom",
    })}
  >
    <div>{mainText}</div>
    <div
      className={cx("linkText")}
      onClick={onLinkClick}
    >
      {linkText}
    </div>
  </div>
);

export default AlertMessageWithLink;
