import React from "react";
import * as PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import classNames from "classnames/bind";
import moduleStyles from "./OverlayLoader.module.scss";

const cx = classNames.bind(moduleStyles);

const OverlayLoader = (props) => {
  return (
    <div className={cx("overlayLoader")}>
      <div className={cx("message")}>
        <FontAwesome
          name="cog"
          className={cx("icon")}
          spin
        />
        {props.text}
      </div>
    </div>
  );
};

OverlayLoader.propTypes = {
  text: PropTypes.string,
};

export default OverlayLoader;
