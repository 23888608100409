import { Pill } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

const getStatusType = (type) => {
  switch (type) {
    case "approved":
    case "validere_approved":
      return { status: "success", text: "Approved" };
    case "approval_required":
      return { status: "warning", text: "Approval Required" };
    case "error":
      return { status: "error", text: "Error" };
    default:
      return { status: "info", text: "-" };
  }
};

const RecordStatus = ({ type }) => {
  const { status, text } = getStatusType(type);

  return <Pill variant={status}>{text}</Pill>;
};

RecordStatus.propTypes = {
  /** Different type of status (successful, pending and error) */
  type: PropTypes.oneOf([
    "approved",
    "validere_approved",
    "approval_required",
    "error",
  ]),
};

export default RecordStatus;
