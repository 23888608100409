import { Button, Modal, Title } from "@validereinc/common-components";
import { RecordValueStatusType } from "@validereinc/domain";
import classNames from "classnames/bind";
import startCase from "lodash/startCase";
import React from "react";
import { recordValueActionTypes } from "../InlineRecordValueStatusPicker/InlineRecordValueStatusPicker";
import styles from "./LockConfirmationModal.module.scss";

const cx = classNames.bind(styles);

const LockConfirmationModal = ({
  onClose,
  action,
  isLoading,
  onConfirm,
  recordsCount,
}: {
  onClose: () => void;
  action: RecordValueStatusType | null;
  isLoading?: boolean;
  onConfirm: () => void;
  recordsCount: number;
}) => {
  const actionDetails = recordValueActionTypes?.[action!];

  if (!actionDetails || !recordsCount) {
    return null;
  }

  return (
    <Modal
      open={!!action}
      onClose={onClose}
    >
      <Modal.Header>
        <Title>{`${startCase(actionDetails.present)} Record Values?`}</Title>
      </Modal.Header>
      <Modal.Body>
        {`You will be ${actionDetails.present}ing ${recordsCount} ${recordsCount > 1 ? "records" : "record"}. Do you wish to continue?`}
      </Modal.Body>

      <Modal.Footer className={cx("footer-buttons")}>
        <Button
          onClick={onConfirm}
          variant="error"
          disabled={isLoading}
        >
          {startCase(actionDetails.present)} Records
        </Button>

        <Button onClick={onClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LockConfirmationModal;
