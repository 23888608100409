import React from "react";

const EmptyChartIcon = () => (
  <svg
    width="39"
    height="22"
    viewBox="0 0 39 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 16L12.287 3.7439L24.0741 16L38.5 1"
      stroke="#2DBFBE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="6"
      y1="17.5"
      x2="6"
      y2="21.5"
      stroke="#2DBFBE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="12"
      y1="10.5"
      x2="12"
      y2="21.5"
      stroke="#2DBFBE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="18"
      y1="17.5"
      x2="18"
      y2="21.5"
      stroke="#2DBFBE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="24"
      y1="19.5"
      x2="24"
      y2="21.5"
      stroke="#2DBFBE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="30"
      y1="17.5"
      x2="30"
      y2="21.5"
      stroke="#2DBFBE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="36"
      y1="8.5"
      x2="36"
      y2="21.5"
      stroke="#2DBFBE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EmptyChartIcon;
