import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import "./CustomList.css";
import { getListHeight } from "./ListHelper";

const WIDTH_OFFSET = 5;

const HEIGHT_OFFSET = 10;

export const CustomList = (props) => {
  const rowCount = props.list.length;

  const cache = useMemo(() => {
    return new CellMeasurerCache({
      defaultHeight: props.rowHeight,
      fixedWidth: true,
    });
  }, [props.rowHeight]);

  // Force recalculation of row height if a new list is given
  useEffect(() => {
    if (props.flexibleRowHeight) {
      cache.clearAll();
    }
  }, [props.list, props.flexibleRowHeight]);

  /**
   * Fixed height if props.height exist,
   * else rowHeight <= listHeight <= maximumListHeight
   * */
  const listHeight =
    props.height ??
    Math.min(props.maximumListHeight, getListHeight(rowCount, props.rowHeight));

  // eslint-disable-next-line react/prop-types
  const rowRenderer = ({ index, key, style, parent }) => {
    const data = props.list[index];
    const rowStyle = { ...style };

    // Remove border from the first row
    if (index === 0) {
      rowStyle.border = "none";
    }

    /**
     * FlexibleRowHeight needs to be measured to render properly, delayed
     * height changes (e.g. image loading) need to call the measure function
     * when done to resize the component properly
     */
    return props.flexibleRowHeight ? (
      <CellMeasurer
        cache={cache}
        key={key}
        parent={parent}
        rowIndex={index}
        columnIndex={0}
      >
        {({ measure, registerChild }) => (
          <div
            className={`customList__row ${props.rowClassName || null}`}
            key={key}
            style={rowStyle}
            ref={registerChild}
          >
            {props.listItemRenderer(data, measure)}
          </div>
        )}
      </CellMeasurer>
    ) : (
      <div
        className={`customList__row ${props.rowClassName || null}`}
        key={key}
        style={rowStyle}
      >
        {props.listItemRenderer(data)}
      </div>
    );
  };

  // eslint-disable-next-line react/prop-types
  const noRowsRenderer = () => {
    return (
      <div className="customList__noRowMessage">No {props.title} available</div>
    );
  };

  return (
    <div
      className={`customList ${props.className || null}`}
      style={{ height: listHeight, ...props.style }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <List
            deferredMeasurementCache={props.flexibleRowHeight ? cache : null}
            width={width - WIDTH_OFFSET}
            height={height - HEIGHT_OFFSET}
            rowCount={rowCount}
            rowHeight={
              props.flexibleRowHeight ? cache.rowHeight : props.rowHeight
            }
            rowRenderer={rowRenderer}
            noRowsRenderer={noRowsRenderer}
            scrollToIndex={props.scrollToIndex ?? 0}
          />
        )}
      </AutoSizer>
    </div>
  );
};

CustomList.propTypes = {
  title: PropTypes.string.isRequired,
  /** maximumListHeight for dynamic height, height for fixed */
  maximumListHeight: PropTypes.number,
  height: PropTypes.number,
  rowHeight: PropTypes.number.isRequired,
  list: PropTypes.array.isRequired,
  listItemRenderer: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  rowClassName: PropTypes.string,
  flexibleRowHeight: PropTypes.bool,
  scrollToIndex: PropTypes.number,
};
