import { PillProps } from "@validereinc/common-components";

type StatusResponseType = { type: PillProps["variant"]; text?: string };

// IMPROVE: clean up this entire file
const getStatusTypeForTests = (status: string): StatusResponseType => {
  switch (status) {
    case "accepted":
      return { type: "success" };
    case "pending":
    case "preliminary":
      return { type: "warning" };
    case "error":
    case "preliminary-error":
      return { type: "error", text: "Error" };
    default:
      return { type: "default" };
  }
};

export const getStatusTypeForSamples = (state: string): StatusResponseType => {
  switch (state) {
    case "validated":
      return { type: "success" };
    case "pending":
      return { type: "warning" };
    case "invalidated":
      return { type: "error" };
    default:
      return { type: "default" };
  }
};

/**
 * Given a form submission state, return a string representing the status type
 * @param {string} state form submission state
 * @returns an object with a type property representing the status type
 */
export const getStatusTypeForForms = (state: string): StatusResponseType => {
  switch (state) {
    case "validated":
      return { type: "success" };
    case "pending":
      return { type: "warning" };
    case "invalidated":
      return { type: "error" };
    case "submitted":
      return { type: "info" };
    default:
      return { type: "default" };
  }
};

export const getStatusType = (
  status: string,
  statusType: string
): { type: PillProps["variant"]; text?: string } => {
  switch (statusType) {
    case "test":
      return getStatusTypeForTests(status);
    case "sample":
      return getStatusTypeForSamples(status);
    case "form":
      return getStatusTypeForForms(status);
    default:
      return { type: "default" };
  }
};
