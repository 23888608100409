import styles from "@validereinc/common-components/constants";
import * as PropTypes from "prop-types";
import React from "react";
import "./Status.scss";

const GREY_COLOUR = "#ddd";

const getStatusColor = (type) => {
  switch (type) {
    case "completed":
    case "successful":
      return styles.status.success;
    case "inprogress":
      return styles.status.default;
    case "pending":
      return styles.status.warning;
    case "error":
      return styles.status.error;
    default:
      return GREY_COLOUR;
  }
};

const Status = ({ type, children }) => {
  return (
    <div className="status">
      <div
        className="status__dot"
        style={{ background: getStatusColor(type) }}
      />
      {children}
    </div>
  );
};

Status.propTypes = {
  /** Different type of status (successful, pending and error) */
  type: PropTypes.oneOf([
    "completed",
    "successful",
    "pending",
    "error",
    "inprogress",
    "default",
  ]),
  /** Description of the status */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Status;
