import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { Collapse } from "react-bootstrap";
import "./Section.scss";

/** If not given the open and setOpen properties, Section will manage its own state */
const Section = ({ open, setOpen, className, style, header, children }) => {
  const [isOpen, setIsOpen] = useState(open ?? true);

  const onHeaderClicked = () => {
    if (open === undefined) {
      setIsOpen(!isOpen);
    } else {
      setOpen?.(!open);
    }
  };

  return (
    <div
      className={`section ${className}`}
      style={style}
    >
      <div
        className="section__header"
        onClick={onHeaderClicked}
      >
        {header}
      </div>

      <Collapse in={open ?? isOpen}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default Section;
