import React from "react";
import * as PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import styles from "./SaveIcon.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const SaveIcon = ({ loadingState, actionState }) => {
  if (loadingState !== "refetching" && loadingState !== "loaded") {
    return null;
  }

  const isRefetching =
    loadingState === "refetching" || actionState === "disabled";

  const iconName = isRefetching ? "refresh" : "check";
  return (
    <div className={cx("container")}>
      <div className={`${cx("icon")} fa-stack`}>
        <FontAwesome
          name="cloud"
          stack="2x"
        />

        <FontAwesome
          name={iconName}
          style={{ color: "white" }}
          spin={isRefetching}
          stack="1x"
        />
      </div>

      {isRefetching ? "Saving..." : "Saved"}
    </div>
  );
};

SaveIcon.propTypes = {
  loadingState: PropTypes.string,
  actionState: PropTypes.string,
};

export default SaveIcon;
