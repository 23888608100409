import { Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { AutoSizer } from "react-virtualized";
import { Borders, Highlight, XAxis, XYPlot, YAxis } from "react-vis";
import "react-vis/dist/style.css";
import { GetXAxisTicks, GraphTimeFormatter } from "../../../utils/charts";
import "./Chart.css";

const Y_PADDING = 0.25;

const NUM_X_AXIS_TICK = 20;

const DEFAULT_HEIGHT = 300;

const LABEL_HEIGHT_OFFSET = 50;

const Label = (props) => (
  <OverlayTrigger
    placement="bottom"
    overlay={<Tooltip id="label__tooltip">{props.label.name}</Tooltip>}
  >
    <div className="chart__label">
      <FontAwesome
        name="circle"
        className="icon"
        style={{ color: props.label.color }}
      />
      {props.label.name}
    </div>
  </OverlayTrigger>
);

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateTable: false,
      zoomXDomain: null,
      zoomYDomain: null,
      xAxisTicks: [],
      disablePointerEvent: false,
    };

    this.onHighlightStartBrush = this.onHighlightStartBrush.bind(this);
    this.getTimeTick = this.getTimeTick.bind(this);
    this.resetAxes = this.resetAxes.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const xDomain = state.zoomXDomain ? state.zoomXDomain : props.xDomain;
    const xAxisTicks = GetXAxisTicks(xDomain, NUM_X_AXIS_TICK);

    return { xAxisTicks: xAxisTicks };
  }

  onHighlightStartBrush() {
    this.setState({
      disablePointerEvent: true,
    });
  }

  onHighlightEndBrush(datapoint) {
    if (datapoint) {
      const zoomXDomain = [new Date(datapoint.left), new Date(datapoint.right)];
      const zoomYDomain = [
        Math.min(datapoint.top, datapoint.bottom),
        Math.max(datapoint.top, datapoint.bottom),
      ];

      this.setState({
        zoomXDomain: zoomXDomain,
        zoomYDomain: zoomYDomain,
        disablePointerEvent: false,
        showResetButton: true,
      });
    } else {
      this.setState({
        disablePointerEvent: false,
      });
    }
  }

  resetAxes() {
    this.setState({
      zoomXDomain: null,
      zoomYDomain: null,
      showResetButton: false,
    });
  }

  getTimeTick(value, index) {
    const { xAxisTicks } = this.state;
    return GraphTimeFormatter(value, index, xAxisTicks);
  }

  haveData(children) {
    return children.some((child) => child.length > 0);
  }

  render() {
    const {
      title,
      height,
      xAxisTitle,
      yAxisTitle,
      xDomain,
      yDomain,
      children,
      labels,
    } = this.props;

    const {
      zoomXDomain,
      zoomYDomain,
      xAxisTicks,
      showResetButton,
      disablePointerEvent,
    } = this.state;

    const chartHeight = height ? height : DEFAULT_HEIGHT;

    if (this.haveData(children)) {
      const childrenWithProps = React.Children.map(children, (child) => {
        if (child) {
          return React.cloneElement(child, {
            style: { pointerEvents: disablePointerEvent ? "none" : "auto" },
          });
        }
        return null;
      });

      return (
        <div className="chart">
          {title ? <Title className="chart__title">{title}</Title> : null}

          <div className="chart__chartContainer">
            <div style={{ flex: "1 1 auto" }}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <XYPlot
                    width={width}
                    height={chartHeight}
                    xDomain={zoomXDomain ? zoomXDomain : xDomain}
                    yDomain={zoomYDomain ? zoomYDomain : yDomain}
                    yPadding={Y_PADDING}
                  >
                    <Highlight
                      enableY={true}
                      onBrushStart={() => this.onHighlightStartBrush()}
                      onBrushEnd={(datapoint) =>
                        this.onHighlightEndBrush(datapoint)
                      }
                    />

                    {childrenWithProps}

                    <Borders
                      style={{
                        bottom: { fill: "#fff" },
                        left: { fill: "#fff" },
                        right: { fill: "#fff" },
                        top: { fill: "#fff" },
                      }}
                    />

                    <XAxis
                      title={xAxisTitle}
                      position="middle"
                      tickValues={xAxisTicks}
                      tickFormat={this.getTimeTick}
                    />

                    <YAxis
                      title={yAxisTitle}
                      position="start"
                      yPadding={Y_PADDING}
                    />
                  </XYPlot>
                )}
              </AutoSizer>
            </div>

            {labels ? (
              <div className="chart__labelContainer">
                <b>Labels</b>
                <div
                  className="chart__labels"
                  style={{ height: chartHeight - LABEL_HEIGHT_OFFSET }}
                >
                  {labels.map((label, index) => (
                    <Label
                      label={label}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>

          {showResetButton ? (
            <div className="chart__resetButtonContainer">
              <Button
                className="chart__resetButton"
                onClick={this.resetAxes}
              >
                Reset
              </Button>
            </div>
          ) : null}
        </div>
      );
    } else {
      return <div className="chart__noDataAvailable">No chart available</div>;
    }
  }
}

Chart.propTypes = {
  title: PropTypes.string,
  height: PropTypes.number,
  xAxisTitle: PropTypes.string,
  yAxisTitle: PropTypes.string,
  xDomain: PropTypes.array,
  yDomain: PropTypes.array,
  labels: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Label.propTypes = {
  label: PropTypes.object,
};

export default Chart;
