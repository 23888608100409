import {
  DateInput,
  SelectInput,
  TextInput,
} from "@validereinc/common-components";
import { getDisplayedUnits } from "@validereinc/utilities";
import startCase from "lodash/startCase";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import CoordinateInputs from "./CoordinateInputs";

const AttributeField = ({
  attribute,
  isDisabled,
  shouldShowDescription = true,
}) => {
  const {
    data_type,
    is_required,
    id,
    name,
    pick_list_values,
    units,
    description,
  } = attribute;

  const commonProps = {
    name: `attribute.${id}`,
    label: name,
    isRequired: is_required,
    isDisabled,
    unit: getDisplayedUnits(units),
    description: shouldShowDescription ? description : null,
  };

  const pickListValues = useMemo(() => {
    if (pick_list_values?.length && typeof pick_list_values?.[0] === "string") {
      return pick_list_values.map((value) => ({
        id: value,
        name: startCase(value),
      }));
    }

    return pick_list_values;
  }, [pick_list_values]);

  switch (data_type) {
    case "string":
      return <TextInput {...commonProps} />;
    case "number":
      return (
        <TextInput
          {...commonProps}
          type="number"
        />
      );
    case "integer":
      return (
        <TextInput
          {...commonProps}
          type="number"
          step={1}
        />
      );
    case "date":
      return <DateInput {...commonProps} />;
    case "pick-list":
      return (
        <SelectInput
          {...commonProps}
          options={pickListValues}
          labelKey="name"
        />
      );
    case "geo_point":
      return <CoordinateInputs {...commonProps} />;
    default:
      return null;
  }
};

AttributeField.propTypes = {
  attribute: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  shouldShowDescription: PropTypes.bool,
};

export default AttributeField;
