import React from "react";

import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DataTable, Icon, Link } from "@validereinc/common-components";
import { isRecordValueLocked, type RecordValueType } from "@validereinc/domain";
import { getFormattedNumberWithUnit } from "@validereinc/utilities";
import classNames from "classnames/bind";
import styles from "./RecordValueCell.module.scss";

const cx = classNames.bind(styles);

export type RecordValueCellProps = {
  value?: RecordValueType;
  onClick?: () => void;
};

export const RecordValueCell = ({ value, onClick }: RecordValueCellProps) => {
  const { getUnitName, getPrecisionByType } = useMeasurementTypes();

  if (!value) return <>-</>;

  const cleanValue =
    value?.value || value?.value === 0
      ? getFormattedNumberWithUnit(
          {
            value: Number(value?.value),
            unit: getUnitName(value?.measurement_unit, value?.value),
          },
          null,
          {
            maxFractionDigits: getPrecisionByType(value?.measurement_type),
            minFractionDigits: 0,
          }
        )
      : "";

  const isClickable = !!cleanValue && !!onClick;

  return (
    <div className={cx("value-container")}>
      {isRecordValueLocked(value) && (
        <span className={cx("lock-icon")}>
          <Icon
            variant="lock-simple"
            size={16}
          />
        </span>
      )}
      {isClickable ? (
        <Link
          onClick={onClick}
          label={cleanValue}
        />
      ) : (
        <DataTable.DataRow.NumberCell
          value={value.value}
          unit={getUnitName(value.measurement_unit)}
        />
      )}
    </div>
  );
};
