import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { Collapse } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import "./SidePanelSection.scss";

const SidePanelSection = (props) => {
  const [open, setOpen] = useState(props.open ?? true);

  return (
    <div className={`sidePanelSection ${props.className ?? ""}`}>
      <div
        className="sidePanelSection__subHeader font-body-16-bold"
        onClick={() => setOpen((open) => !open)}
      >
        <div>
          {props.subHeader}

          <FontAwesome
            className="fa-fw"
            name={open ? "angle-up" : "angle-down"}
          />
        </div>

        <div className="pull-right">{props.caption}</div>
      </div>
      <Collapse in={open}>
        <div>{props.children}</div>
      </Collapse>
    </div>
  );
};

SidePanelSection.propTypes = {
  className: PropTypes.string,
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  open: PropTypes.bool,
};

export default SidePanelSection;
