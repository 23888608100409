import {
  useHasRecordLockingPermission,
  useUpdateStatusOfValuesOfRecord,
} from "#src/components/hooks/adapters/useRecords";
import {
  PillPopover,
  PillPopoverOptionType,
} from "@validereinc/common-components";
import {
  RecordValueStatusType,
  RecordValueType,
  getRecordStatusFromValues,
} from "@validereinc/domain";
import React from "react";

export const recordValueStatusPickerOptions: Array<
  PillPopoverOptionType<RecordValueStatusType>
> = [
  {
    label: "Unlocked",
    value: "unlocked",
    variant: "info",
    icon: null, // null icon renders no icon or dot
  },
  { label: "Locked", value: "locked", icon: "lock-simple" },
];

export const recordValueActionTypes: Record<
  RecordValueStatusType,
  { present: string; past: string }
> = {
  locked: { present: "lock", past: "locked" },
  unlocked: { present: "unlock", past: "unlocked" },
};

const InlineRecordValueStatusPicker = ({
  recordId,
  values,
  onSuccess,
}: {
  recordId: string;
  values: RecordValueType[];
  onSuccess?: () => void;
}) => {
  const setRecordValueStatus = useUpdateStatusOfValuesOfRecord({ onSuccess });

  const hasLockPermission = useHasRecordLockingPermission();

  const value = getRecordStatusFromValues(values);

  /*
  For partially locked state, we need to manually override
  the face of PillPopover component:
  */
  const partiallyLockedRenderComponent = (
    <PillPopover.Display
      isBusy={setRecordValueStatus.isLoading}
      label="Partially Locked"
      variant="info"
      hasDot={false}
      isEditable
    />
  );

  return (
    <PillPopover
      options={recordValueStatusPickerOptions}
      value={value}
      isBusy={setRecordValueStatus.isLoading}
      onChange={(newValue) => {
        setRecordValueStatus.mutate({
          recordId,
          measurementTypes: values.map((va) => va.measurement_type),
          status: newValue,
        });
      }}
      isEditable={hasLockPermission}
      componentOverride={
        value === "partially_locked"
          ? partiallyLockedRenderComponent
          : undefined
      }
    />
  );
};

export default InlineRecordValueStatusPicker;
