import React from "react";
import * as PropTypes from "prop-types";
import AttributeField from "#common/AttributeField/AttributeField";

export const ATTRIBUTE_FIELD_TYPES = {
  GEO_POINT: "geo_point",
};

const defaultAttributes = [
  {
    data_type: ATTRIBUTE_FIELD_TYPES.GEO_POINT, // an array of two numbers, [longitude, latitude]
    description: "Location",
    id: "coordinates",
    is_required: false,
    name: "Location",
    isDisabled: false,
  },
];

/**
 * renders a list of AttributeField components
 * we can pass in a list of attributes to render or a list of attributes types and then it will filter from a list of default attributes
 * for the difference between attributes & customAttributes
 * see https://docs.google.com/document/d/1iml-HQZnpln_y-VXUkn04FWlbwnh1r8em1xCvn1416M
 */
const AttributeFieldList = ({
  attributes = null,
  attributeFieldTypes = [ATTRIBUTE_FIELD_TYPES.GEO_POINT],
  isDisabled = false,
}) => {
  if (attributes) {
    return attributes.length > 0
      ? attributes.map((attribute, index) => {
          return (
            <AttributeField
              attribute={attribute}
              key={attribute?.id ?? index}
              isDisabled={isDisabled}
            />
          );
        })
      : null;
  }

  // if no attributes are passed in, we will filter the default attributes by the attributeFieldTypes
  const filteredAttributes = defaultAttributes.filter((attribute) => {
    return attributeFieldTypes.includes(attribute.data_type);
  });

  return filteredAttributes.length > 0
    ? filteredAttributes.map((attribute, index) => {
        return (
          <AttributeField
            attribute={attribute}
            key={attribute?.id ?? index}
            isDisabled={isDisabled}
          />
        );
      })
    : null;
};

AttributeFieldList.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      data_type: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      is_required: PropTypes.boolean,
      name: PropTypes.string,
      isDisabled: PropTypes.boolean,
    })
  ),
  attributeFieldTypes: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
};

export default AttributeFieldList;
