export const RELATIVE_TIME_RANGE_OPTIONS = {
  one_hour: {
    label: "1H",
    value: 1,
    unit: "hours",
  },
  four_hours: {
    label: "4H",
    value: 4,
    unit: "hours",
  },
  eight_hours: {
    label: "8H",
    value: 8,
    unit: "hours",
  },
  twelve_hours: {
    label: "12H",
    value: 12,
    unit: "hours",
  },
  twenty_hours: {
    label: "24H",
    value: 24,
    unit: "hours",
  },
};

export const RELATIVE_DATE_RANGE_OPTIONS = {
  seven_days: {
    label: "7D",
    value: 7,
    unit: "days",
  },
  thirty_days: {
    label: "30D",
    value: 30,
    unit: "days",
  },
  sixty_days: {
    label: "60D",
    value: 60,
    unit: "days",
  },
  ninety_days: {
    label: "90D",
    value: 90,
    unit: "days",
  },
};
