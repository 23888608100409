import React, { useState } from "react";

import * as PropTypes from "prop-types";

import classNames from "classnames/bind";

import styles from "./Input.module.scss";
import FontAwesome from "react-fontawesome";

const cx = classNames.bind(styles);

const Input = ({ value, onChange, placeholder, icon, disabled = false }) => {
  const [focus, setFocus] = useState(false);

  return (
    <div className={cx("container", { focus, disabled })}>
      {icon ? <FontAwesome name={icon} /> : null}

      <input
        className={cx("input", { withIcon: !!icon })}
        value={value}
        onChange={({ target: { value: newValue } }) => onChange(newValue)}
        placeholder={placeholder}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        disabled={disabled}
      />
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Input;
