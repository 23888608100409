export function getListHeight(rowCount, rowHeight) {
  if (rowCount > 0) {
    return rowCount * rowHeight;
  }

  return rowHeight;
}

export function getListContainerHeight(maxVisibleRows, rowHeight, list) {
  if (!rowHeight) {
    return 0;
  }

  const visibleListHeight = rowHeight * maxVisibleRows;
  const totalListHeight = getListHeight(list.length, rowHeight);

  return Math.min(visibleListHeight, totalListHeight);
}
