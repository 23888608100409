export function isLastPanel(currentIndex, numOfPanels) {
  return currentIndex === numOfPanels - 1;
}

export function getNewWidth(
  currentPanelWidth,
  nextPanelWidth,
  widthDifference,
  minWidth
) {
  currentPanelWidth = currentPanelWidth + widthDifference;
  nextPanelWidth = nextPanelWidth - widthDifference;

  if (currentPanelWidth < minWidth) {
    const difference = minWidth - currentPanelWidth;
    return [minWidth, nextPanelWidth - difference];
  } else if (nextPanelWidth < minWidth) {
    const difference = minWidth - nextPanelWidth;
    return [currentPanelWidth - difference, minWidth];
  } else {
    return [currentPanelWidth, nextPanelWidth];
  }
}
