import moment from "moment";
import ceil from "lodash/ceil";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import round from "lodash/round";
import { GetTimeInterval } from "#utils/charts";

const TICK_WIDTH = 35;
const MINIMUM_RANGE = 0.1;
const SECONDS_PER_DAY = 60 * 60 * 24;
const SECONDS_IN_FIVE_MINUTES = 60 * 5;
const MINIMUM_TIME_TICKS_PER_DAY = 3;

/**
 * x = 10^log(x)[base 10]           // since exponents and logs are inverse
 * ln x = ln(10) * log(x)[base 10]  // take the ln of both sides and apply power rule
 * ln x / ln 10 = log(x)[base 10]   // divide both sides by ln(10)
 *
 * log(x)[base 10] is the number of times you have to multiply 10 to get the number,
 * e.g. x = 1 * 10^log(x)[base 10], which is the order of magnitude
 *
 * precision is the negative of this because the ceil function takes in negative numbers to decrease
 * precision (e.g. -1 makes it go up to nearest 10, -2 => nearest 100), which is what we want when
 * figuring out the maximum value to put on the graph
 */
export const getPrecision = (value) => {
  if (value === 0) {
    return 0;
  }
  const orderOfMagnitude = Math.floor(Math.log(value) / Math.LN10);
  return -orderOfMagnitude;
};

/**
 * Given an array of data with the `y` property, creates a scale that allows
 * with evenly spaced ticks. Priority is given to making the ticks as readable
 * as possible, which means adjusting the min and max domain as necessary.
 *
 * @param {*} data An array of objects, each having a `y` property
 * @param {*} yDomain An object that contains `max` and `min` properties
 * @param {*} numGridlines The number of gridlines to be shown
 */
export const calculateYProperties = (data, yDomain, numGridlines = 4) => {
  const yTickValues = [];
  const yGridlineValues = [];
  let minY;
  let maxY;

  if (data?.length) {
    let maxData = yDomain?.max ? yDomain.max : maxBy(data, (data) => data.y).y;

    if (maxData === 0) {
      maxData = MINIMUM_RANGE;
    }

    const precision = getPrecision(maxData / numGridlines);
    maxY = ceil(maxData / numGridlines, precision) * numGridlines;

    const step = maxY / numGridlines;
    for (let i = 0; i <= numGridlines; i++) {
      yTickValues.push(round(step * i, precision + 1));
      yGridlineValues.push(round(step * i, precision + 1));
    }
  }

  return [minY ?? 0, maxY ?? 0, yTickValues, yGridlineValues];
};

const roundToNearestX = (number, roundedTo) => {
  return Math.ceil(number / roundedTo) * roundedTo;
};

/**
 * Given an array of data with the `x` property, creates a scale that allows
 * with evenly spaced ticks. Priority is given to making the ticks as readable
 * as possible, which means adjusting the min and max domain as necessary.
 *
 * To switch from daily resolution to sub-daily resolution, the tick intervals
 * have to be small enough that it produces multiple "time" ticks between "day"
 * ticks
 *
 * @param {*} data An array of objects, each having a `x` value
 * @param {*} width The width of the entire table
 */
export const calculateXProperties = (data, width) => {
  const xTickValues = [];
  let minX;
  let maxX;
  let tickInterval;

  if (data?.length) {
    minX = minBy(data, (data) => data.x).x;
    maxX = maxBy(data, (data) => data.x).x;

    const numTicks = Math.floor(width / TICK_WIDTH);

    const differenceInSeconds = moment.duration(maxX - minX).asSeconds();

    tickInterval = GetTimeInterval(differenceInSeconds, numTicks);

    if (tickInterval < SECONDS_PER_DAY / MINIMUM_TIME_TICKS_PER_DAY) {
      tickInterval = roundToNearestX(tickInterval, SECONDS_IN_FIVE_MINUTES);

      minX = moment(minX).startOf("hour").valueOf();
      maxX = moment(maxX).endOf("hour").valueOf();
    } else {
      tickInterval = roundToNearestX(tickInterval, SECONDS_PER_DAY);

      minX = moment(minX).startOf("day").valueOf();
      maxX = moment(maxX).endOf("day").valueOf();
    }

    for (let i = 0; i < numTicks; i++) {
      xTickValues.push(
        moment(minX)
          .add(tickInterval * i, "seconds")
          .valueOf()
      );
    }
  }

  return [minX ?? 0, maxX ?? 0, xTickValues, tickInterval];
};
