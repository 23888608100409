import { AssertIsSameDate } from "#utils/assert";
import { getColorPalette } from "#utils/styleCalculator";
import { getTimeStringFromDate, TIMEZONE } from "#utils/timeFormatter";
import styles from "@validereinc/common-components/constants";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import FontAwesome from "react-fontawesome";
import { AutoSizer } from "react-virtualized";
import {
  ChartLabel,
  Crosshair,
  HorizontalGridLines,
  LineSeries,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import config from "../../../config";
import "./LineChart.scss";
import { calculateXProperties, calculateYProperties } from "./LineChartHelper";

const DEFAULT_CHART_MARGIN = {
  left: 40,
  bottom: 25,
};
const CHART_MARGINS_WITH_LABEL = {
  left: 70,
  bottom: 45,
};
const SECONDS_PER_DAY = 60 * 60 * 24;
const JANUARY_MONTH_INDEX = 0;

const LegendItem = ({ color, text }) => {
  return (
    <div className="legendItem">
      <FontAwesome
        name="square"
        className="icon"
        style={{ color }}
      />

      <div className="legendItem__text">{text}</div>
    </div>
  );
};

const CrosshairRow = ({ title, value, color }) => (
  <section className="tooltip__row">
    <div className="tooltip__title">
      <FontAwesome
        name="minus"
        className="icon"
        style={{ color }}
      />

      <span>{title}</span>
    </div>

    <span className="tooltip__value">{value}</span>
  </section>
);

const splitIntoCategories = (data, getCategory) => {
  const categoryObject = {};

  data.forEach((d) => {
    const category = getCategory(d);
    if (categoryObject[category]) {
      categoryObject[category].push(d);
    } else {
      categoryObject[category] = [d];
    }
  });
  return categoryObject;
};

// if getCategory is given, there are multiple series
const categorizeData = (data, getCategory) => {
  if (!getCategory) {
    return [[data]];
  }

  const categorizedData = splitIntoCategories(data, getCategory);

  const colorPalette = getColorPalette(Object.keys(categorizeData).length);

  const categories = {};

  Object.keys(categorizedData).forEach((category, index) => {
    categories[category] = {
      title: category,
      color: colorPalette[index],
    };
  });

  return [Object.values(categorizedData), categories];
};

const LineChart = ({
  className = "",
  yLabel,
  xLabel,
  data = [],
  yDomain,
  getCategory,
  getValue,
  height,
  width,
  showChartLegend = true,
}) => {
  const [crosshairValues, setCrosshairValues] = useState([]);

  const [minY, maxY, yTickValues, yGridlineValues] = useCallback(
    calculateYProperties(data, yDomain),
    [data, yDomain]
  );

  const [minX, maxX, xTickValues, xTickInterval] = useCallback(
    calculateXProperties(data, width),
    [data, width]
  );

  const [formattedData, categories] = categorizeData(data, getCategory);

  const xTickFormatter = (dataPoint, index, xTickValues, xTickInterval) => {
    const prevTick = xTickValues?.[index - 1] ?? dataPoint;

    if (xTickInterval < SECONDS_PER_DAY) {
      if (AssertIsSameDate(dataPoint, prevTick, "day")) {
        return getTimeStringFromDate(dataPoint, "HH:mm");
      }

      return (
        <tspan className="lineChart__tick">
          {getTimeStringFromDate(dataPoint, "MM/DD")}
        </tspan>
      );
    } else {
      if (!AssertIsSameDate(dataPoint, prevTick, "month")) {
        const monthOfYear = moment(dataPoint).month();

        // show the month if it's the start of the month, except January,
        // showing the year instead
        const tickValue =
          monthOfYear > JANUARY_MONTH_INDEX
            ? getTimeStringFromDate(dataPoint, "MMM")
            : getTimeStringFromDate(dataPoint, "YYYY");

        return <tspan className="lineChart__tick">{tickValue}</tspan>;
      }

      return moment(dataPoint).date();
    }
  };

  const onNearestX = (value) => {
    setCrosshairValues([value]);
  };

  const onMouseLeave = () => {
    setCrosshairValues([]);
  };

  const sharedStyleProps = {
    line: { stroke: "#ccc" },
    text: { fill: styles.textColour },
  };

  const margin = {
    left: yLabel ? CHART_MARGINS_WITH_LABEL.left : DEFAULT_CHART_MARGIN.left,
    bottom: xLabel
      ? CHART_MARGINS_WITH_LABEL.bottom
      : DEFAULT_CHART_MARGIN.bottom,
  };

  return formattedData.length ? (
    <div
      className={`lineChart ${className}`}
      style={{ height, width }}
    >
      {categories && showChartLegend && (
        <div className="lineChart__legend">
          {Object.values(categories).map((category) => (
            <LegendItem
              key={category.title}
              color={category.color}
              text={category.title}
            />
          ))}
        </div>
      )}

      <div className="lineChart__chart">
        <AutoSizer disableWidth>
          {({ height }) => (
            <XYPlot
              height={height}
              width={width}
              yDomain={[minY, maxY]}
              xDomain={[minX, maxX]}
              margin={margin}
              onMouseLeave={onMouseLeave}
            >
              <HorizontalGridLines
                tickValues={yGridlineValues}
                className="horizontalGridline"
              />

              <XAxis
                tickFormat={(data, index) =>
                  xTickFormatter(data, index, xTickValues, xTickInterval)
                }
                tickValues={xTickValues}
                tickSizeInner={0}
                style={sharedStyleProps}
              />

              <YAxis
                tickValues={yTickValues}
                style={sharedStyleProps}
              />

              {yLabel && (
                <ChartLabel
                  text={yLabel}
                  className="lineChart__chartLabel"
                  xPercent={0.01}
                  yPercent={0.25}
                  style={{
                    transform: "rotate(-90)",
                    style: {
                      textAnchor: "middle",
                    },
                  }}
                />
              )}

              {xLabel && (
                <ChartLabel
                  text={xLabel}
                  className="lineChart__chartLabel"
                  xPercent={0.5}
                  yPercent={0.8}
                />
              )}

              {/* A hidden series that enables the crosshair for 2 or more LineSeries */}
              <LineSeries
                data={data}
                color="none"
                onNearestX={onNearestX}
              />

              {formattedData.map((data, index) => (
                <LineSeries
                  key={index}
                  data={data}
                />
              ))}

              {crosshairValues && (
                <Crosshair
                  values={crosshairValues}
                  className="lineChart__crosshair"
                >
                  <div className="lineChart__crosshairTooltip">
                    <div className="tooltipTitle">
                      {moment(crosshairValues?.[0]?.x)
                        .tz(TIMEZONE)
                        .format(config.DATETIME_FORMAT)}
                    </div>

                    {crosshairValues.map((value) => {
                      const category = categories[getCategory(value)];

                      if (category) {
                        return (
                          <CrosshairRow
                            key={category}
                            color={category.color}
                            title={category.title}
                            value={getValue(value)}
                          />
                        );
                      }
                    })}
                  </div>
                </Crosshair>
              )}
            </XYPlot>
          )}
        </AutoSizer>
      </div>
    </div>
  ) : (
    <div
      style={{ width }}
      className="lineChart__noData"
    >
      No data available
    </div>
  );
};

LineChart.propTypes = {
  /** The height of the chart container */
  height: PropTypes.number.isRequired,
  /** The width of the chart container */
  width: PropTypes.number.isRequired,
  /** An array of objects containing an x and y property */
  data: PropTypes.array.isRequired,
  /** A className to be added last to the chart container */
  className: PropTypes.string,
  /** The chart label for the Y axis */
  yLabel: PropTypes.string,
  /** The chart label for the X axis */
  xLabel: PropTypes.string,
  /**
   * An object with `max` and `min` property that changes the range
   * of the chart to be at least that big. May be adjusted based on
   * evening out tick intervals
   */
  yDomain: PropTypes.object,
  /**
   * A function that shows which property in the array of data differentiates
   * belonging to another LineSeries
   */
  getCategory: PropTypes.func,
  /** The formatter function that gets the value to be shown in the crosshair */
  getValue: PropTypes.func,
  /** Chart legends */
  showChartLegend: PropTypes.bool,
};

LegendItem.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
};

CrosshairRow.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
};

export default LineChart;
