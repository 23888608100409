import { localStorageToJSON } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import FontAwesome from "react-fontawesome";
import "./ResizePanels.scss";
import { getNewWidth, isLastPanel } from "./ResizePanelsHelper";

const MIN_WIDTH_REQUIREMENT_PERCENT = 5;

function getDefaultPanelWidth(defaultPanelWidth, panelWidthKey: string) {
  return localStorageToJSON(panelWidthKey) ?? defaultPanelWidth;
}

const ResizePanels = (props) => {
  const [panelWidth, setPanelWidth] = useState(
    getDefaultPanelWidth(props.defaultPanelWidth, props.panelWidthKey)
  );
  const [currentPanelIndex, setCurrentPanelIndex] = useState(null);
  const [initialPosition, setInitialPosition] = useState(null);
  const [widthDifference, setWidthDifference] = useState(null);

  const onControllerClicked = (event, index) => {
    event.preventDefault();
    setInitialPosition(event.clientX);
    setCurrentPanelIndex(index);
  };

  const onResize = (event) => {
    if (currentPanelIndex !== null && currentPanelIndex >= 0) {
      const pixelDifference = event.clientX - initialPosition;

      if (pixelDifference !== 0) {
        setWidthDifference(pixelDifference);
      }
    }
  };

  const onStopResize = (event) => {
    if (currentPanelIndex !== null && widthDifference !== null) {
      const newPanelWidth = [...panelWidth];
      const percentDifference = (widthDifference / event.view.innerWidth) * 100;

      const newWidthAdjustment = getNewWidth(
        panelWidth[currentPanelIndex],
        panelWidth[currentPanelIndex + 1],
        percentDifference,
        MIN_WIDTH_REQUIREMENT_PERCENT
      );

      newPanelWidth[currentPanelIndex] = newWidthAdjustment[0];
      newPanelWidth[currentPanelIndex + 1] = newWidthAdjustment[1];

      setPanelWidth(newPanelWidth);
      setWidthDifference(null);

      // Cache the width in the local storage
      if (props.panelWidthKey) {
        localStorage.setItem(
          props.panelWidthKey,
          JSON.stringify(newPanelWidth)
        );
      }
    }

    setCurrentPanelIndex(null);
  };

  useEffect(() => {
    window.addEventListener("mousemove", onResize);
    window.addEventListener("mouseup", onStopResize);

    return () => {
      window.removeEventListener("mousemove", onResize);
      window.removeEventListener("mouseup", onStopResize);
    };
  });

  return (
    <div
      className="resizePanels"
      style={{ height: props.height }}
    >
      {React.Children.map(props.children, (child, index) => {
        return (
          <>
            <div
              style={{
                width: `${panelWidth[index]}%`,
                pointerEvents: currentPanelIndex !== null ? "none" : null,
              }}
            >
              {child}
            </div>

            {!isLastPanel(index, props.children.length) && (
              <div
                className="widthController"
                onMouseDown={(event) => onControllerClicked(event, index)}
                style={
                  currentPanelIndex === index ? { left: widthDifference } : null
                }
              >
                <FontAwesome name="ellipsis-h" />
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

ResizePanels.propTypes = {
  height: PropTypes.number.isRequired,
  defaultPanelWidth: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.array,
  panelWidthKey: PropTypes.string,
};

export default ResizePanels;
