import { Button, Modal, Title, useAlert } from "@validereinc/common-components";
import startCase from "lodash/startCase";
import * as PropTypes from "prop-types";
import React, { useState } from "react";

const DeleteModal = ({
  open,
  onClose,
  doDelete,
  onDelete,
  entityName = "entity",
  instanceName,
  action = { present: "delete", past: "deleted" },
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addAlert } = useAlert();
  const formattedName = `"${instanceName}"`;

  const handleDelete = async () => {
    try {
      setIsSubmitting(true);
      await doDelete();

      addAlert({
        variant: "success",
        message: `Successfully ${action.past} ${formattedName}.`,
      });

      onDelete();
      setIsSubmitting(false);
      onClose();
    } catch (err) {
      setIsSubmitting(false);
      addAlert({
        variant: "error",
        message: `Unable to ${action.present} ${formattedName}.`,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Modal.Header>
        <Title>{`${startCase(action.present)} ${formattedName}?`}</Title>
      </Modal.Header>
      <Modal.Body>
        {`Are you sure you want to ${
          action.present
        } this ${entityName.toLowerCase()}? This action cannot be undone.`}
      </Modal.Body>

      <Modal.Footer className="clearfix modal-footer">
        <Button
          onClick={handleDelete}
          variant="error"
          disabled={isSubmitting}
        >
          {startCase(action.present)}
        </Button>

        <Button onClick={onClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  doDelete: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  entityName: PropTypes.string,
  instanceName: PropTypes.string.isRequired,
  action: PropTypes.object,
};

export default DeleteModal;
