import React from "react";
import PropTypes from "prop-types";
import { Col, Row, ControlLabel } from "react-bootstrap";
import FormInputTitle from "./FormInputTitle";

const CustomInput = (props) => {
  return (
    <Row className={`formRows ${props.className ?? ""}`}>
      <Col
        componentClass={ControlLabel}
        sm={props.titleCol}
      >
        <FormInputTitle
          title={props.title}
          message={props.message}
          pullLeft={props.pullLeft}
        />
      </Col>
      <Col sm={12 - props.titleCol}>{props.children}</Col>
    </Row>
  );
};

CustomInput.propTypes = {
  titleCol: PropTypes.number,
  title: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  message: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  pullLeft: PropTypes.bool,
};

export default CustomInput;
