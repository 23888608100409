import React from "react";
import * as PropTypes from "prop-types";

const GREY = "#AAA";

export const CircleWithLetterSVG = ({
  letter = "",
  color = GREY,
  position = { x: -10, y: -10 },
}) => {
  const { x, y } = { ...position };

  return (
    <svg
      x={x}
      y={y}
    >
      <g>
        <circle
          cx="10"
          cy="10"
          r="7"
          fill={color}
        ></circle>
        <text
          x="10"
          y="13.24"
          textAnchor="middle"
          fontSize="9"
          fill="#fff"
          fontWeight="bold"
        >
          {letter}
        </text>
      </g>
    </svg>
  );
};

CircleWithLetterSVG.propTypes = {
  position: PropTypes.object,
  style: PropTypes.object,
  size: PropTypes.number,
  letter: PropTypes.string,
  color: PropTypes.string,
};
