import moment from "moment";
import {
  AssertIsBeforeDate,
  AssertIsAfterDate,
  AssertIsSameDate,
  AssertIsAfterOrEqualDate,
  AssertIsBeforeOrEqualDate,
} from "#utils/assert";
import { RELATIVE_DATE_RANGE_OPTIONS } from "./RelativeRangeOptions";
import find from "lodash/find";
import { getTimeStringFromDate, getUTCTimeString } from "#utils/timeFormatter";
import config from "../../../config";

export const isDisabledDay = (day, disabledDays) => {
  if (!disabledDays) {
    return false;
  }

  return disabledDays.some((disabledDay) => {
    const { before, after } = disabledDay ?? {};

    if (before && AssertIsBeforeDate(day, before, "day")) {
      return true;
    }

    if (after && AssertIsAfterDate(day, after, "day")) {
      return true;
    }

    if (AssertIsSameDate(day, disabledDay)) {
      return true;
    }
  });
};

export const isValidDaySelection = (
  activeKey,
  selectedDate,
  from,
  to,
  allowOneDayRange
) => {
  switch (activeKey) {
    case "from":
      if (allowOneDayRange) {
        return AssertIsBeforeOrEqualDate(selectedDate, to, "day");
      } else {
        return AssertIsBeforeDate(selectedDate, to, "day");
      }
    case "to":
      if (allowOneDayRange) {
        return AssertIsAfterOrEqualDate(selectedDate, from, "day");
      } else {
        return AssertIsAfterDate(selectedDate, from, "day");
      }
    default:
      return false;
  }
};

export const isValidDateInput = (date, activeKey, otherDate) => {
  const REASONABLE_START_DATE = moment("1970-01-01", config.DATE_FORMAT);
  const REASONABLE_END_DATE = moment("2038-12-31", config.DATE_FORMAT);

  if (activeKey === "to" && !AssertIsAfterOrEqualDate(date, otherDate, "day")) {
    return false;
  }

  if (
    activeKey === "from" &&
    !AssertIsBeforeOrEqualDate(date, otherDate, "day")
  ) {
    return false;
  }

  return (
    AssertIsAfterOrEqualDate(date, REASONABLE_START_DATE, "day") &&
    AssertIsBeforeOrEqualDate(date, REASONABLE_END_DATE, "day")
  );
};

export const getHoverRange = (range, startDate) => {
  if (!range) {
    return {};
  }

  let from = moment(startDate);
  let to = moment(startDate);

  if (range === "monthly") {
    from = from.startOf("month").toDate();
    to = to.endOf("month").toDate();
  } else if (range === "weekly") {
    from = from.startOf("week").toDate();
    to = to.endOf("week").toDate();
  }

  return {
    hoverRange: { from, to },
    hoverRangeStart: from,
    hoverRangeEnd: to,
  };
};

export const getRelativeOptions = (relativeDayRange) => {
  const relativeDateRangeOption = find(RELATIVE_DATE_RANGE_OPTIONS, {
    value: relativeDayRange,
  });

  if (relativeDateRangeOption) {
    return relativeDateRangeOption;
  }

  return null;
};

export const getFormatDate = (date, dateFormat, utc) => {
  if (utc) {
    return getUTCTimeString(date, dateFormat);
  }

  return getTimeStringFromDate(date, dateFormat);
};

export const getDateObject = (date, utc) => {
  if (utc) {
    const removeTimeFromDate = moment.utc(date).format(config.DATE_FORMAT);
    return moment(removeTimeFromDate).toDate();
  } else {
    return moment(date).toDate();
  }
};

export const getMoment = (date, utc) => {
  if (utc) {
    return moment.utc(date);
  }

  return moment(date);
};
