import React from "react";
import * as PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import {
  getProfileColor,
  getFirstChar,
} from "../../../../utils/styleCalculator";
import "./ProfileIcon.css";

export const ProfileIcon = ({ username, fontSize }) => {
  const userFirstChar = getFirstChar(username);
  return (
    <div
      className="fa-stack fa-lg profileIcon icon"
      style={fontSize ? { fontSize } : null}
    >
      <FontAwesome
        name="square"
        stack="2x"
        style={{ color: getProfileColor(userFirstChar) }}
      />
      <span className="fa-stack-1x circle-text">
        <b>{userFirstChar}</b>
      </span>
    </div>
  );
};

ProfileIcon.propTypes = {
  username: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
};
