import React from "react";
import PropTypes from "prop-types";
import { FormGroup } from "react-bootstrap";
import "./Form.css";

const Form = (props) => (
  <FormGroup className={props.className}>{props.children}</FormGroup>
);

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

export default Form;
