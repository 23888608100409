import { TextInput } from "@validereinc/common-components";
import {
  isValidLatitudeDegree,
  isValidLongitudeDegree,
  parseCoordinateAsDegree,
} from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React from "react";

/**
 * Coordinate Inputs for longitude and latitude which accept either number or a proper coordinate like 43.6532° N
 * https://docs.google.com/document/d/1jTDCmd5LqmdMQpFtZfTTgXpa_7IieEMEEFbi2rdWMnw
 */
const CoordinateInputs = (props) => {
  return (
    <>
      <TextInput
        {...props}
        name={`${props.name}[0]`}
        label="Latitude"
        validate={{
          validValue: (value) => {
            if (!value && !props.isRequired) {
              return null;
            }

            return !isValidLatitudeDegree(parseCoordinateAsDegree(value))
              ? "Latitude must be between -90 and 90"
              : null;
          },
        }}
      />

      <TextInput
        {...props}
        name={`${props.name}[1]`}
        label="Longitude"
        validate={{
          validValue: (value) => {
            if (!value && !props.isRequired) {
              return null;
            }

            return !isValidLongitudeDegree(parseCoordinateAsDegree(value))
              ? "Longitude must be between -180 and 180"
              : null;
          },
        }}
      />
    </>
  );
};

CoordinateInputs.propTypes = {
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
};

export default CoordinateInputs;
