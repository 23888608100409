import React from "react";

import * as PropTypes from "prop-types";

import classNames from "classnames/bind";

// REVIEW: direct imports are causing style pollution. This should be cleaned up.
import "./SummaryInformation.module.scss";
import styles from "./SummaryInformation.module.scss";

const cx = classNames.bind(styles);

const SummaryInformationContainer = ({
  children,
  variant = "horizontal",
  status,
  statusVariant = "default",
}) => (
  <div
    className={cx("summaryContainer", {
      vertical: variant === "vertical",
      status: !!status,
    })}
  >
    {status && (
      <div className={cx("statusContainer", statusVariant)}>
        <div className={cx("pill")}>
          <div className={cx("pillIndicator", statusVariant)} />
          {status}
        </div>
      </div>
    )}
    {children}
  </div>
);

SummaryInformationContainer.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  status: PropTypes.string,
  statusVariant: PropTypes.string,
};

export default SummaryInformationContainer;
