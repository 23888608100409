import { Info, Link } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";

import { useNavigate } from "#routers/hooks";
import { displayValue } from "#utils/stringFormatter";

// REVIEW: direct imports are causing style pollution. This should be cleaned up.
import "./SummaryInformation.module.scss";
import styles from "./SummaryInformation.module.scss";

const cx = classNames.bind(styles);

const SummaryInformation = ({
  title,
  description,
  value,
  style,
  titleStyle,
  className,
  type = "horizontal",
  capitalized,
  spaceBetween,
  link,
  suppress = false,
  allowWrap = false,
  isLoading = false,
  isStatusPanel = false,
}) => {
  const navigate = useNavigate();

  const valueInlineStyle = {
    textTransform: capitalized ? "capitalize" : "initial",
    whiteSpace: allowWrap ? "normal" : "nowrap",
    fontSize: isStatusPanel ? "14px" : "16px",
  };

  if (link && value) {
    value = (
      <Link
        label={value}
        style={valueInlineStyle}
        onClick={() => navigate({ pathname: link })}
      />
    );
  }

  return (
    <section
      className={cx("summaryInformation", className, {
        summaryInformation__vertical: type === "vertical",
        summaryInformation__spaceBetween: spaceBetween,
      })}
      style={style}
    >
      <div
        className={cx(
          "summaryInformation__title",
          isStatusPanel && "summaryInformation__title--small"
        )}
        style={titleStyle}
      >
        {title}
        {description && <Info content={description} />}
      </div>

      {type === "horizontal" && !spaceBetween ? ": " : null}

      <div
        className={cx("summaryInformation__value", {
          skeleton: isLoading,
        })}
        style={valueInlineStyle}
        data-hj-suppress={suppress}
      >
        {displayValue(value)}
      </div>
    </section>
  );
};

SummaryInformation.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  style: PropTypes.object,
  titleStyle: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.oneOf(["horizontal", "vertical"]),
  capitalized: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  link: PropTypes.string,
  allowWrap: PropTypes.bool,
  /** If true, will block out summary information value for hotjar recordings */
  suppress: PropTypes.string,
  isLoading: PropTypes.bool,
  isStatusPanel: PropTypes.bool,
};

export default SummaryInformation;
