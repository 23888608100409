import React from "react";
import { CircleWithLetterSVG } from "./SVG";
import * as PropTypes from "prop-types";

export const VirtualSampleSVG = (props) => {
  const defaultColor = "#5736ab";

  return (
    <CircleWithLetterSVG
      {...props}
      letter="V"
      color={props.color ? props.color : defaultColor}
      style={{ pointerEvents: "none" }}
    />
  );
};

VirtualSampleSVG.propTypes = {
  color: PropTypes.string,
};
