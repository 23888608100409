import React, { CSSProperties } from "react";
import * as PropTypes from "prop-types";
import { getProgressBarWidth, getProgressColor } from "#utils/styleCalculator";

const ProgressBar = (props: {
  value: number;
  min: number;
  max: number;
  style?: CSSProperties;
}) => {
  const { value, min, max, style } = props;

  return (
    <div
      style={{
        width: "100%",
        height: "5px",
        backgroundColor: "#dadada",
        borderRadius: "2px",
        display: "inline-block",
        ...style,
      }}
    >
      <div
        style={{
          width: getProgressBarWidth(value || 0, min, max),
          height: "100%",
          backgroundColor: getProgressColor(value || 0, min, max),
          borderRadius: "2px",
          transition: "all .2s ease-out",
        }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  style: PropTypes.object,
};

export default ProgressBar;
