import { Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";

const FormTitle = (props) => {
  return (
    <div
      className="form__title"
      style={props.pullLeft && { textAlign: "left" }}
    >
      {props.message ? (
        <Tooltip
          content={props.message}
          className={props.messageClassName ?? ""}
          position={["top", "right"]}
        >
          {props.title}
          <FontAwesome
            name="info-circle"
            style={{ marginLeft: "3px" }}
          />
        </Tooltip>
      ) : (
        props.title
      )}
    </div>
  );
};

FormTitle.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  pullLeft: PropTypes.bool,
  messageClassName: PropTypes.string,
};

export default FormTitle;
