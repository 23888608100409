import config from "#config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Pill } from "@validereinc/common-components";
import classNames from "classnames/bind";
import get from "lodash/get";
import startCase from "lodash/startCase";
import React from "react";
import { getStatusType } from "./rendererHelper";
import styles from "./renderers.module.scss";

const cx = classNames.bind(styles);

// This renderer can be removed along with the "isSampleListEnabled" flag
export const dateRenderer = (rowData, columnKey) => {
  return getTimeStringFromDate(get(rowData, columnKey), config.DATETIME_FORMAT);
};

export const dateTimeRenderer = (rowData, columnKey) => {
  const dateTime = get(rowData, columnKey);
  if (dateTime) {
    return (
      <div>
        {getTimeStringFromDate(dateTime, config.DATEMONTH_FORMAT)}
        <br />
        <div className={cx("timeContainer")}>
          {getTimeStringFromDate(dateTime, "hh:mm A z")}
        </div>
      </div>
    );
  } else {
    return "-";
  }
};

/* eslint-disable react/display-name */

export const statusRenderer = (statusType) => (rowData, columnKey) => {
  const status = get(rowData, columnKey, "-");

  const { type, text } = getStatusType(status, statusType);

  return <Pill variant={type}>{text ? text : startCase(status)}</Pill>;
};

export const pillRenderer = (type) => (rowData, columnKey) => {
  const text = get(rowData, columnKey);

  return text ? <Pill variant={type}>{text}</Pill> : "-";
};
